import React, {
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  Box,
  TextField,
  Card,
  CardContent,
  Unstable_Grid2 as Grid,
  Drawer,
  MenuItem,
  useMediaQuery,
  Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { Routes, useLocation, Outlet, useParams, useNavigate, type NavigateFunction } from 'react-router-dom';


import MaterialReactTable, {
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  type MRT_Virtualizer,
} from 'material-react-table';

import {
  QueryClient,
  QueryClientProvider,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import { AppDispatch, RootState, useTypedDispatch, useTypedSelector } from '../app/store';
import { Member, deleteMember, fetchMemberDeposits, fetchMembers, selectMembers, setSelectedMember } from '../features/members/memberSlice';
import ConfirmDialog from '../Components/Common/ConfirmDialog';
import { defaultMemberData } from '../features/auth/authSlice';

type Person = {
  name: {
    firstName: string;
    lastName: string;
  };
  address: string;
  city: string;
  state: string;
};

//nested data is ok, see accessorKeys in ColumnDef below
const data1: Person[] = [
  {
    name: {
      firstName: 'John',
      lastName: 'Doe',
    },
    address: '261 Erdman Ford',
    city: 'East Daphne',
    state: 'Kentucky',
  },
  {
    name: {
      firstName: 'Jane',
      lastName: 'Doe',
    },
    address: '769 Dominic Grove',
    city: 'Columbus',
    state: 'Ohio',
  },
  {
    name: {
      firstName: 'Joe',
      lastName: 'Doe',
    },
    address: '566 Brakus Inlet',
    city: 'South Linda',
    state: 'West Virginia',
  },
  {
    name: {
      firstName: 'Kevin',
      lastName: 'Vandy',
    },
    address: '722 Emie Stream',
    city: 'Lincoln',
    state: 'Nebraska',
  },
  {
    name: {
      firstName: 'Joshua',
      lastName: 'Rolluffs',
    },
    address: '32188 Larkin Turnpike',
    city: 'Omaha',
    state: 'Nebraska',
  },
];

const ExampleWithReactQueryProvider = ({ data, onClickDelete, onClickMenuItem }: { data: Member[], onClickDelete: Function, onClickMenuItem: Function }) => {

  const navigate = useNavigate();

  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<Member>[]>(
    () => [
      {
        accessorKey: 'full_name', //access nested data with dot notation
        header: 'Full Name',
      },
      {
        accessorKey: 'mobile',
        header: 'Mobile',
      },
      {
        accessorKey: 'email', //normal accessorKey
        header: 'Email',
      },
      {
        accessorKey: 'role',
        header: 'Role',
      }
    ],
    [],
  );

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <MaterialReactTable
        enablePagination={false}
        enableColumnActions={false}
        initialState={{ columnVisibility: desktop ? {} : { address: false, city: false, state: false } }}
        enableRowActions
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <MenuItem key="details" onClick={() => onClickMenuItem('details', row.original, closeMenu)}>
            Details
          </MenuItem>,
          // <MenuItem key="details" onClick={() => navigate(`/members/${row.original.id}`, { state: { row: row.original } })}>
          //   Details
          // </MenuItem>,
          <MenuItem key="edit" onClick={() => navigate(`/members/edit/${row.original.id}`, { state: { row: row.original } })}>
            Edit
          </MenuItem>,
          <MenuItem key="delete" onClick={() => onClickDelete(row.original, closeMenu)}>
            Delete
          </MenuItem>,
        ]}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Fab size="small" color="primary" aria-label="add" onClick={() => navigate("/members/add")}>
              <AddIcon />
            </Fab>
          </Box>
        )}
        columns={columns}
        data={data} />
    </>
  )
};

// We use RootState we defined earlier
// to make `useSelector` understand 
// the store structure via type assertion 
// with the `TypedUseSelectorHook` generic:
// const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
// const useTypedDispatch = () => useDispatch<AppDispatch>();

const Members = () => {
  const { memberId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const showSideWindow = () => {
    return memberId || pathname === "/members/add" || (pathname === "/members/edit" && memberId) ? true : false;
  }
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState(false);
  const [selectedMember, setSelectedMemberL] = useState<Member>({} as Member);

  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchMembers());
  }, []);

  // Now, use the selector inside right away,
  // no need to destructure the result:
  const members: Member[] = useTypedSelector(selectMembers);

  useEffect(() => {
    if (memberId && members.length > 0 && members !== undefined) {
      const selectedMem: Member = members.find((row: Member) => row.id == Number(memberId)) || defaultMemberData;
      dispatch(setSelectedMember(selectedMem));
      if (selectedMem.id)
        dispatch(fetchMemberDeposits(selectedMem.id));
    }
  }, [members, memberId]);


  const onClickDeleteMember = (member: Member, closeMenu: Function) => {
    closeMenu();
    setSelectedMemberL(member);
    setDeleteConfirmDialog(true);
  }

  const onClickMemberMenuItem = async (key: string, member: Member, closeMenu: Function) => {
    closeMenu();
    await dispatch(setSelectedMember(member));

    switch (key) {
      case 'details':
        navigate(`/members/${member.id}`, { state: { row: member } })
        break;

      default:
        break;
    }
  }

  const onDeleteConfirmYes = () => {
    setDeleteConfirmDialog(false);
    dispatch(deleteMember(selectedMember));
  }

  const onDeleteConfirmNo = () => {
    setDeleteConfirmDialog(false);
  }

  return (
    <>
      <ConfirmDialog
        open={deleteConfirmDialog}
        onYes={onDeleteConfirmYes}
        onNo={onDeleteConfirmNo}
        text={`Sure to delete ${selectedMember.full_name} ?`} />
      <Box sx={{ py: { xs: 1, md: 2 } }}>
        <Typography variant="h6" component="h6">
          List of Members
        </Typography>
      </Box>
      <ExampleWithReactQueryProvider data={members} onClickDelete={onClickDeleteMember} onClickMenuItem={onClickMemberMenuItem} />
      <Drawer
        anchor={'right'}
        open={showSideWindow()}
      >
        <Box sx={{ width: { xs: window.innerWidth, md: 500 } }}>
          <Outlet />
        </Box>
      </Drawer>
    </>
  );

}
export default Members;