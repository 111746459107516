import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState, useTypedDispatch } from "../../app/store";
import { ApiResponseType, HTTP } from "../../services/Api";
import { setFromApiResponse } from "../notification/snackbarNotificationSlice";
import { stat } from "fs";
import { uploadFileURL } from "../../services/UploadApi";
import { defaultMemberData } from "../auth/authSlice";
import { DepositType } from "../deposits/depositSlice";

export enum MemberRole {
    admin = 'admin',
    accountant = 'accountant',
    member = 'member'
}

export type Member = {
    id?: number;            // Add '?' such that Pick allows you to skip the field (i.e. not force to set))
    full_name?: string;
    gender?: string;
    username?: string;
    password?: string;
    role: keyof typeof MemberRole;
    status?: number;
    mobile?: number;
    email?: string;
    address?: string;
    photo?: string;
    created_date?: string;
    updated_date?: string;
    created_by?: number;
    updated_by?: number;
};

type MemberState = {
    list: Member[];
    isLoading: boolean;
    error: string | null | undefined;

    isMemberAdding: boolean;
    isMemberUpdating: boolean;
    addMemberResponse: ApiResponseType;
    updateMemberResponse: ApiResponseType;

    selectedMember: Member;

    memberDeposits: DepositType[];
};

const addMemberResponseDefault = { data: [], errors: {}, message: "", success: false };

const initialState: MemberState = {
    list: [],
    isLoading: false,
    error: null,
    isMemberAdding: false,
    isMemberUpdating: false,
    addMemberResponse: addMemberResponseDefault,
    updateMemberResponse: addMemberResponseDefault,
    selectedMember: { role: "member" },
    memberDeposits: []
};

export const fetchMemberDeposits = createAsyncThunk("members/fetchMemberDeposits", async (memberId: number) => {
    const res = await HTTP.get('/deposits', { member_id: memberId });
    return res.data.data;
});

export const fetchMembers = createAsyncThunk("members/fetchMembers", async () => {
    console.log('Fetch called.');
    const res = await HTTP.get('/users');
    return res.data.data.map((row: Member) => ({ ...row, photo: uploadFileURL(row.photo), mobile: `+88${row.mobile}` }));
});

export const addMember = createAsyncThunk("members/addMember", async (data: Member, { dispatch }) => {
    console.log('Add member slice.');
    const res = await HTTP.post('/user', data);
    await dispatch(fetchMembers());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const updateMember = createAsyncThunk("members/updateMember", async (data: Member, { dispatch }) => {
    // console.log('Add member slice.');
    const res = await HTTP.put('/user', data);
    await dispatch(fetchMembers());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const deleteMember = createAsyncThunk("members/deleteMember", async (data: Member, { dispatch }) => {
    console.log('Delete member slice.');
    const res = await HTTP.delete('/user', { id: data.id });
    await dispatch(fetchMembers());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const memberSlice = createSlice({
    // A name, used in action types:
    name: "members",

    // The initial state:
    initialState,

    // An object of "case reducers". 
    // Key names will be used to generate actions:
    reducers: {
        resetAddMemberResponse: (state) => {
            state.addMemberResponse = addMemberResponseDefault;
            state.updateMemberResponse = addMemberResponseDefault;
        },
        setMemberList: (state, action: PayloadAction<Member[]>) => {
            state.list = action.payload;
        },
        setSelectedMember: (state, action: PayloadAction<Member>) => {
            state.selectedMember = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchMembers.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchMembers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.list = action.payload;
        });
        builder.addCase(fetchMembers.rejected, (state, action) => {
            state.isLoading = false;
            state.list = [];
            state.error = action.error.message;
        });
        builder.addCase(addMember.pending, (state, action) => {
            state.isMemberAdding = true;
        });
        builder.addCase(addMember.fulfilled, (state, action) => {
            state.addMemberResponse = action.payload;
            state.isMemberAdding = false;
        });
        builder.addCase(addMember.rejected, (state, action) => {
            state.isMemberAdding = false;
        });
        builder.addCase(updateMember.pending, (state, action) => {
            state.isMemberUpdating = true;
        });
        builder.addCase(updateMember.fulfilled, (state, action) => {
            state.updateMemberResponse = action.payload;
            state.isMemberUpdating = false;
        });
        builder.addCase(updateMember.rejected, (state, action) => {
            state.isMemberUpdating = false;
        });
        builder.addCase(fetchMemberDeposits.fulfilled, (state, action) => {
            state.memberDeposits = action.payload;
        });
    },
});

// Export all of the actions:
export const { resetAddMemberResponse, setMemberList, setSelectedMember } = memberSlice.actions;

// It is a convention to export reducer as a default export:
export default memberSlice.reducer;

// Create and export the selector:
export const selectMembers = (state: RootState) => state.members.list;