import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState, useTypedDispatch } from "../../app/store";
import { ApiResponseType, HTTP } from "../../services/Api";
import { setFromApiResponse } from "../notification/snackbarNotificationSlice";
import { stat } from "fs";
import { uploadFileURL } from "../../services/UploadApi";
import { defaultMemberData } from "../auth/authSlice";
import { updateMember } from "../members/memberSlice";

export type DepositType = {
    id?: number;
    member_id?: number;
    member_name?: string;
    date?: string;
    amount?: number;
    created_date?: string;
    updated_date?: string;
    created_by?: number;
    updated_by?: number;
};

type DepositState = {
    list: DepositType[];
    isLoading: boolean;
    error: string | null | undefined;

    isDepositAdding: boolean;
    isDepositUpdating: boolean;
    addDepositResponse: ApiResponseType;
    updateDepositResponse: ApiResponseType;

    selectedDeposit: DepositType;
};

const addMemberResponseDefault = { data: [], errors: {}, message: "", success: false };

const initialState: DepositState = {
    list: [],
    isLoading: false,
    error: null,
    isDepositAdding: false,
    isDepositUpdating: false,
    addDepositResponse: addMemberResponseDefault,
    updateDepositResponse: addMemberResponseDefault,
    selectedDeposit: {}
};



export const fetchDeposits = createAsyncThunk("deposits/fetchDeposits", async () => {
    const res = await HTTP.get('/deposits');
    return res.data.data;
});

export const addDeposit = createAsyncThunk("deposits/addDeposit", async (data: DepositType, { dispatch }) => {
    const res = await HTTP.post('/deposit', data);
    await dispatch(fetchDeposits());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const updateDeposit = createAsyncThunk("deposits/updateDeposit", async (data: DepositType, { dispatch }) => {
    const res = await HTTP.put('/deposit', data);
    await dispatch(fetchDeposits());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const deleteDeposit = createAsyncThunk("deposits/deleteDeposit", async (data: DepositType, { dispatch }) => {
    const res = await HTTP.delete('/deposit', { id: data.id });
    await dispatch(fetchDeposits());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const depositSlice = createSlice({
    // A name, used in action types:
    name: "deposits",

    // The initial state:
    initialState,

    // An object of "case reducers". 
    // Key names will be used to generate actions:
    reducers: {
        resetDepositAddEditResponse: (state) => {
            state.addDepositResponse = addMemberResponseDefault;
            state.updateDepositResponse = addMemberResponseDefault;
        },
        setSelectedDeposit: (state, action: PayloadAction<DepositType>) => {
            state.selectedDeposit = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchDeposits.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchDeposits.fulfilled, (state, action) => {
            state.isLoading = false;
            state.list = action.payload;
        });
        builder.addCase(fetchDeposits.rejected, (state, action) => {
            state.isLoading = false;
            state.list = [];
            state.error = action.error.message;
        });
        builder.addCase(addDeposit.pending, (state, action) => {
            state.isDepositAdding = true;
        });
        builder.addCase(addDeposit.fulfilled, (state, action) => {
            state.addDepositResponse = action.payload;
            state.isDepositAdding = false;
        });
        builder.addCase(addDeposit.rejected, (state, action) => {
            state.isDepositAdding = false;
        });
        builder.addCase(updateDeposit.pending, (state, action) => {
            state.isDepositUpdating = true;
        });
        builder.addCase(updateDeposit.fulfilled, (state, action) => {
            state.updateDepositResponse = action.payload;
            state.isDepositUpdating = false;
        });
        builder.addCase(updateDeposit.rejected, (state, action) => {
            state.isDepositUpdating = false;
        });
    },
});

// Export all of the actions:
export const { resetDepositAddEditResponse, setSelectedDeposit } = depositSlice.actions;

// It is a convention to export reducer as a default export:
export default depositSlice.reducer;

// Create and export the selector:
// export const selectMembers = (state: RootState) => state.members.list;