// import { ROLE } from './roles';
import { useSelector } from 'react-redux';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { RootState } from '../app/store';
import { MemberRole } from '../features/members/memberSlice';
import Loading from '../Components/Common/Loading';

// export enum ROLE {
//     Admin = 'Admin',
//     User = 'User',
// }

const PrivateRoute = ({
    children,
    roles,
}: {
    children: JSX.Element;
    roles: Array<keyof typeof MemberRole>;
}) => {
    let location = useLocation();
    const { token, userData, loading } = useSelector((state: RootState) => state.auth);

    if (loading) {
        return <Loading />;
    }

    const userHasRequiredRole = userData && roles.includes(userData.role) ? true : false;

    if (!token) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    if (token && !userHasRequiredRole) {
        // return <AccessDenied />; // build your won access denied page (sth like 404)
        return <Navigate to="/" state={{ from: location }} />;
    }

    return children;
};

export default PrivateRoute;