import { Routes, Route, Outlet, NavLink } from 'react-router-dom';
import Dashboard from '../Pages/Dashboard';
import Members from '../Pages/Members';
import MemberDetail from '../Components/Member/MemberDetail';
import MemberAdd from '../Components/Member/MemberAdd';
import MemberEdit from '../Components/Member/MemberEdit';
import Layout from '../Components/Layout';
import Login from '../Pages/Login';
import PrivateRoute from './PrivateRoute';
import { MemberRole } from '../features/members/memberSlice';
import Deposits from '../Pages/Deposits';
import DepositAdd from '../Components/Deposit/DepositAdd';
import DepositEdit from '../Components/Deposit/DepositEdit';
import DepositDetail from '../Components/Deposit/DepositDetail';
import Expenses from '../Pages/Expenses';
import ExpenseAdd from '../Components/Expense/ExpenseAdd';
import ExpenseDetails from '../Components/Expense/ExpenseDetails';
import ExpenseEdit from '../Components/Expense/ExpenseEdit';
import AccountSummary from '../Pages/AccountSummary';

const RouteIndex = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route element={<PrivateRoute roles={Object.values(MemberRole)}><Layout /></PrivateRoute>}>
        <Route index element={<PrivateRoute roles={Object.values(MemberRole)}><Dashboard /></PrivateRoute>} />
        <Route path="account-summary" element={<PrivateRoute roles={Object.values(MemberRole)}><AccountSummary /></PrivateRoute>} />
        <Route path="members" element={<PrivateRoute roles={Object.values(MemberRole)}><Members /></PrivateRoute>}>
          <Route path="add" element={<PrivateRoute roles={[MemberRole.admin]}><MemberAdd /></PrivateRoute>} />
          <Route path=":memberId" element={<PrivateRoute roles={Object.values(MemberRole)}><MemberDetail /></PrivateRoute>} />
          <Route path="edit/:memberId" element={<PrivateRoute roles={[MemberRole.admin]}><MemberEdit /></PrivateRoute>} />
        </Route>
        <Route path="deposits" element={<PrivateRoute roles={Object.values(MemberRole)}><Deposits /></PrivateRoute>}>
          <Route path="add" element={<PrivateRoute roles={[MemberRole.admin]}><DepositAdd /></PrivateRoute>} />
          <Route path=":depositId" element={<PrivateRoute roles={Object.values(MemberRole)}><DepositDetail /></PrivateRoute>} />
          <Route path="edit/:depositId" element={<PrivateRoute roles={[MemberRole.admin]}><DepositEdit /></PrivateRoute>} />
        </Route>
        <Route path="expenses" element={<PrivateRoute roles={Object.values(MemberRole)}><Expenses /></PrivateRoute>}>
          <Route path="add" element={<PrivateRoute roles={[MemberRole.admin, MemberRole.accountant]}><ExpenseAdd /></PrivateRoute>} />
          <Route path=":expenseId" element={<PrivateRoute roles={Object.values(MemberRole)}><ExpenseDetails /></PrivateRoute>} />
          <Route path="edit/:expenseId" element={<PrivateRoute roles={[MemberRole.admin, MemberRole.accountant]}><ExpenseEdit /></PrivateRoute>} />
        </Route>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Route>
    </Routes>
  );
};


export default RouteIndex;