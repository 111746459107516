import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  Box,
  TextField,
  Card,
  CardContent,
  Unstable_Grid2 as Grid,
  Avatar
} from '@mui/material';
import { Routes, Route, Outlet, NavLink } from 'react-router-dom';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { blue } from '@mui/material/colors';
import { fullAccountSummary } from '../services';

type Summary = {
  total_deposit: number;
  total_expense: number;
  current_balance: number;
  today_deposit: number;
  today_expense: number;
  week_deposit: number;
  week_expense: number;
  month_deposit: number;
  month_expense: number;
};

const SummaryDefaults = {
  total_deposit: 0,
  total_expense: 0,
  current_balance: 0,
  today_deposit: 0,
  today_expense: 0,
  week_deposit: 0,
  week_expense: 0,
  month_deposit: 0,
  month_expense: 0
};

const AccountSummary = () => {
  const [summery, setSummery] = React.useState<Summary>(SummaryDefaults);

  const loadSummery = async () => {
    const res = await fullAccountSummary();
    setSummery(res.data);
  }

  React.useEffect(() => {
    loadSummery();
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid xs={12} md={6} >
        <Card variant='outlined' sx={{ p: 1 }}>
          <Typography textAlign={'center'} variant='h6'>Up to Today</Typography>
          <Typography textAlign={'left'} variant='body2'>Total Deposits tk. {summery.total_deposit}/-</Typography>
          <Typography textAlign={'left'} variant='body2'>Total Expenses tk. {summery.total_expense}/-</Typography>
          <Typography textAlign={'left'} variant='body2'>Current Balance tk. {summery.current_balance}/-</Typography>
        </Card>
      </Grid>
      <Grid xs={12} md={6} >
        <Card variant='outlined' sx={{ p: 1 }}>
          <Typography textAlign={'center'} variant='h6'>Today</Typography>
          <Typography textAlign={'left'} variant='body2'>Deposits tk. {summery.today_deposit}/-</Typography>
          <Typography textAlign={'left'} variant='body2'>Expenses tk. {summery.today_expense}/-</Typography>
        </Card>
      </Grid>
      <Grid xs={12} md={6} >
        <Card variant='outlined' sx={{ p: 1 }}>
          <Typography textAlign={'center'} variant='h6'>This Week</Typography>
          <Typography textAlign={'left'} variant='body2'>Deposits tk. {summery.week_deposit}/-</Typography>
          <Typography textAlign={'left'} variant='body2'>Expenses tk. {summery.week_expense}/-</Typography>
        </Card>
      </Grid>
      <Grid xs={12} md={6} >
        <Card variant='outlined' sx={{ p: 1 }}>
          <Typography textAlign={'center'} variant='h6'>This Month</Typography>
          <Typography textAlign={'left'} variant='body2'>Deposits tk. {summery.month_deposit}/-</Typography>
          <Typography textAlign={'left'} variant='body2'>Expenses tk. {summery.month_expense}/-</Typography>
        </Card>
      </Grid>
    </Grid>
  );
}
export default AccountSummary;