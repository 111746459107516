import React, {
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  Box,
  TextField,
  Card,
  CardContent,
  Unstable_Grid2 as Grid,
  Drawer,
  MenuItem,
  useMediaQuery,
  Fab,
  Link as MuiLink
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { Link, useLocation, Outlet, useParams, useNavigate, type NavigateFunction } from 'react-router-dom';


import MaterialReactTable, {
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  type MRT_Virtualizer,
} from 'material-react-table';

import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import { AppDispatch, RootState, useTypedDispatch, useTypedSelector } from '../app/store';
import ConfirmDialog from '../Components/Common/ConfirmDialog';
import { DepositType, fetchDeposits, resetDepositAddEditResponse, setSelectedDeposit } from '../features/deposits/depositSlice';
import { deleteDeposit } from '../features/deposits/depositSlice';

const Deposits = () => {
  const { depositId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const showSideWindow = () => {
    return depositId || pathname === "/deposits/add" || (pathname === "/deposits/edit" && depositId) ? true : false;
  }

  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchDeposits());
  }, []);

  const { list, selectedDeposit, isLoading } = useSelector((state: RootState) => state.deposits)

  useEffect(() => {
    if (depositId && list.length > 0 && list !== undefined) {
      const selectedItem: DepositType = list.find((row: DepositType) => row.id == Number(depositId)) || {};
      dispatch(setSelectedDeposit(selectedItem));
    }
  }, [list, depositId]);

  const onClickDepositMenuItem = async (key: string, deposit: DepositType, closeMenu: Function) => {
    // console.log("Edit Member: ", deposit);
    closeMenu();
    await dispatch(setSelectedDeposit(deposit));

    switch (key) {
      case 'details':
        navigate(`/deposits/${deposit.id}`);
        break;
      case 'edit':
        navigate(`/deposits/edit/${deposit.id}`);
        break;
      case 'delete':
        setDeleteConfirmDialog(true);
        break;

      default:
        break;
    }
  }

  const onDeleteConfirmYes = () => {
    setDeleteConfirmDialog(false);
    dispatch(deleteDeposit(selectedDeposit));
  }

  const onDeleteConfirmNo = () => {
    setDeleteConfirmDialog(false);
  }

  const goDepositList = async () => {
    await dispatch(resetDepositAddEditResponse());
    navigate("/deposits");
  }

  const depositColumns = useMemo<MRT_ColumnDef<DepositType>[]>(
    () => [
      {
        accessorKey: 'date', //access nested data with dot notation
        header: 'Date',
      },
      {
        accessorKey: 'member_name',
        header: 'Member',
        Cell: ({ cell, column, row }) => (
          <Link to={`/members/${row.original.member_id}`}>
              {cell.getValue<string>()}
          </Link>
        ),
      },
      {
        accessorKey: 'amount', //normal accessorKey
        header: 'Amount',
      }
    ],
    [],
  );

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Box sx={{ py: { xs: 1, md: 2 } }}>
        <Typography variant="h6" component="h6">
          List of Deposits
        </Typography>
      </Box>
      <MaterialReactTable
        enablePagination={false}
        enableColumnActions={false}
        initialState={{ columnVisibility: desktop ? {} : { address: false, city: false, state: false } }}
        enableRowActions
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem key="details" onClick={() => onClickDepositMenuItem('details', row.original, closeMenu)}>
            Details
          </MenuItem>,
          // <MenuItem key="details" onClick={() => navigate(`/members/${row.original.id}`, { state: { row: row.original } })}>
          //   Details
          // </MenuItem>,
          <MenuItem key="edit" onClick={() => onClickDepositMenuItem('edit', row.original, closeMenu)}>
            Edit
          </MenuItem>,
          <MenuItem key="delete" onClick={() => onClickDepositMenuItem('delete', row.original, closeMenu)}>
            Delete
          </MenuItem>,
        ]}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Fab size="small" color="primary" aria-label="add" onClick={() => navigate("/deposits/add")}>
              <AddIcon />
            </Fab>
          </Box>
        )}
        columns={depositColumns}
        data={list} />
      <ConfirmDialog
        open={deleteConfirmDialog}
        onYes={onDeleteConfirmYes}
        onNo={onDeleteConfirmNo}
        text={`Sure to delete deposit tk. ${selectedDeposit.amount} of ${selectedDeposit.member_id} ?`} />
      <Drawer
        anchor={'right'}
        open={showSideWindow()}
      >
        <Box sx={{ width: { xs: window.innerWidth, md: 500 } }}>
          {depositId && !selectedDeposit.id ? <Typography>Loading..</Typography> : <Outlet context={{ goDepositList }} />}
        </Box>
      </Drawer>
    </>
  );

}
export default Deposits;