import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
// Import our reducer from the sluce:
import memberReducer from "../features/members/memberSlice";
import snackbarNotificationReducer from "../features/notification/snackbarNotificationSlice";
import authReducer from "../features/auth/authSlice";
import depositReducer from "../features/deposits/depositSlice";
import expenseReducer from "../features/expenses/expenseSlice";
import expenseCategoryReducer from "../features/expense_cetegories/expenseCategorySlice";

// Use `configureStore` function to create the store:
export const store = configureStore({
    reducer: {
        // Specify our reducer in the reducers object:
        auth: authReducer,
        members: memberReducer,
        deposits: depositReducer,
        expenses: expenseReducer,
        expenseCategories: expenseCategoryReducer,
        snackbarNotification: snackbarNotificationReducer,
    },
});

// Define the `RootState` as the return type:
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useTypedDispatch = () => useDispatch<AppDispatch>()