import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    AppBar,
    Button,
    Toolbar,
    Typography,
    Box,
    TextField,
    Card,
    CardContent,
    Unstable_Grid2 as Grid,
    Avatar,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    Divider,
    Stack,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper
} from '@mui/material';
import { RootState, useTypedDispatch, useTypedSelector } from '../../app/store';
import { AmountToWord, columnSum, price_in_words } from '../../utils/HelperFunctions';
import { accountsSummery } from '../../services/MemberService';

const MemberDeposits = () => {
    const dispatch = useTypedDispatch();
    const { memberDeposits, selectedMember } = useTypedSelector((state: RootState) => state.members);
    const [summery, setSummery] = React.useState<{ balance: number, deposit: number, expense_per_share: number, expense_total: number }>({ balance: 0, deposit: 0, expense_per_share: 0, expense_total: 0 });

    const loadSummery = async () => {
        if (selectedMember.id) {
            const res = await accountsSummery(selectedMember.id);
            console.log('data:', res.data);
            setSummery(res.data);
        }
    }

    React.useEffect(() => {
        loadSummery();
    }, [selectedMember]);

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid xs={12} xl={6} >
                    <Card variant='outlined' sx={{ p: 1 }}>
                        <Typography textAlign={'center'} variant='h6'>Balance tk. {summery.balance.toFixed(2)}/-</Typography>
                        <Typography textAlign={'center'} variant='body2'>({AmountToWord(summery.balance.toFixed(2))})</Typography>
                    </Card>
                </Grid>
                <Grid xs={12} xl={6}>
                    <Card variant='outlined' sx={{ p: 1 }}>
                        <Typography textAlign={'center'} variant='h6'>Deposit tk. {columnSum(memberDeposits, 'amount')}/-</Typography>
                        <Typography textAlign={'center'} variant='body2'>({AmountToWord(columnSum(memberDeposits, 'amount'))})</Typography>
                    </Card>
                </Grid>
            </Grid>

            <TableContainer component={Paper} sx={{ marginTop: 1 }}>
                <Table sx={{ minWidth: { xs: '100%', md: 450 } }} aria-label="simple table" size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Deposit Date</TableCell>
                            <TableCell align="right">Amount (tk)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {memberDeposits.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.date}
                                </TableCell>
                                <TableCell align="right">{row.amount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </React.Fragment >
    );
}
export default MemberDeposits;