import React from 'react';
import logo from './logo.svg';
import './App.css';
import NotifySnackbar from './Components/Common/NotifySnackbar';
import RouteIndex from './routes';


const App = () => {
  return (
    <>
      <NotifySnackbar />
      <RouteIndex />
    </>
  );
};

export default App;
