import { API_SERVER, HTTP } from "./Api";

export type FileType = {
    id?: number;
    client_name?: string;
    file_name?: string;
    file_url?: string;
    file_type?: string;
    file_size?: number;
    file_ext?: number;
    of_name?: number;
    off_id?: number;
    created_date?: string;
    updated_date?: string;
    created_by?: number;
    updated_by?: number
};

export const viewFileNewTab = (file: FileType) => {
    const link = document.createElement('a');
    link.download = file.client_name || "mdsir-download";
    link.target = "_blank";
    link.href = file.file_url || "";
    link.click();
}

export const uploadFileURL = (fileName?: string) => {
    return fileName ? `${API_SERVER}/mdsir_uploads/${fileName}` : "";
}

export const upload = (target: HTMLInputElement, data: { of_name: string, of_id: number, old_file_name?: any }) => {
    const files = target.files instanceof FileList ? target.files[0] : null;

    if (!files)
        return false;

    return HTTP.client.post("/upload", {
        ...data,
        files,
    }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const upload1 = (target: HTMLInputElement, data: { of_name: string, of_id?: number, of_field: string }) => {
    const files = target.files instanceof FileList ? target.files[0] : null;

    if (!files)
        return false;

    return HTTP.client.post(`/upload1/${data.of_name}/${data.of_field}/${data.of_id}`, {
        files,
    }, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const getFiles = (data: { of_name: string, of_id: number }) => {
    return HTTP.get('/upload', data);
};

export const deleteFile = (file: FileType) => {
    return HTTP.delete('/upload', file);
};