// Add Edit Contact form
// export const CONFIG = {
//     tablePG: [50, 100, { text: "All", value: -1 }],
//   };

//   export const GENDERS = ["male", "female", "other"];
//   export const BUSINESS_TYPES = ["client", "loan", "employee", "purchase"];
//   export const CONTACT_TYPES = ["individual", "company"];
//   export const NAV_MENU_REPORTS = {
//     icon: "bar_chart",
//     title: "Reports",
//     router_name: "daily_expense_report",
//     perm: ["admin"],
//     items: [
//       {
//         icon: "dynamic_feed",
//         title: "Daily Expense",
//         router_name: "daily_expense_report",
//         perm: ["admin"],
//       },
//       {
//         icon: "arrow_circle_up",
//         title: "Daily Received",
//         router_name: "daily_received_report",
//         perm: ["admin"],
//       },
//       {
//         icon: "dynamic_feed",
//         title: "Invoice Dues",
//         router_name: "invoice_dues_report",
//         perm: ["admin"],
//       },
//       {
//         icon: "arrow_circle_up",
//         title: "Bill Dues",
//         router_name: "bill_dues_report",
//         perm: ["admin"],
//       },
//       {
//         icon: "arrow_circle_up",
//         title: "Loan Dues",
//         router_name: "loan_dues_report",
//         perm: ["admin"],
//       },
//       {
//         icon: "arrow_circle_up",
//         title: "Profit & Loss",
//         router_name: "monthly_profit_loss",
//         perm: ["admin"],
//       },
//       {
//         icon: "arrow_circle_up",
//         title: "Cash Inflows",
//         router_name: "monthly_cash_inflows",
//         perm: ["admin"],
//       },
//       {
//         icon: "arrow_circle_up",
//         title: "Cash Outflows",
//         router_name: "monthly_cash_outflows",
//         perm: ["admin"],
//       },
//     ],
//   };

export const DAY_NAMES = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
export const MYSQL_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const MYSQL_DATE_FORMAT = "YYYY-MM-DD";
export const MYSQL_TIME_FORMAT = "HH:mm:ss";
export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "hh:mm a";
export const VARCHAR_MAX_LENGTH = 255;
export const TEXT_MAX_LENGTH = 65335;

