import React, {
    UIEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTheme } from '@mui/material/styles';
import {
    AppBar,
    Button,
    Toolbar,
    Typography,
    Box,
    TextField,
    Card,
    CardContent,
    Unstable_Grid2 as Grid,
    Drawer,
    MenuItem,
    useMediaQuery,
    Fab,
    Link as MuiLink
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { Link, useLocation, Outlet, useParams, useNavigate, type NavigateFunction } from 'react-router-dom';


import MaterialReactTable, {
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_SortingState,
    type MRT_Virtualizer,
} from 'material-react-table';

import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import { AppDispatch, RootState, useTypedDispatch, useTypedSelector } from '../app/store';
import ConfirmDialog from '../Components/Common/ConfirmDialog';
import { DepositType, fetchDeposits, resetDepositAddEditResponse, setSelectedDeposit } from '../features/deposits/depositSlice';
import { deleteDeposit } from '../features/deposits/depositSlice';
import { ExpenseType, deleteExpense, fetchExpenses, resetExpenseAddEditResponse, setSelectedExpense } from '../features/expenses/expenseSlice';

const Expenses = () => {
    const { expenseId } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const showSideWindow = () => {
        return expenseId || pathname === "/expenses/add" || (pathname === "/expenses/edit" && expenseId) ? true : false;
    }

    const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState(false);

    const dispatch = useTypedDispatch();

    useEffect(() => {
        dispatch(fetchExpenses());
    }, []);

    const { list, selectedExpense, isLoading } = useTypedSelector((state: RootState) => state.expenses)

    useEffect(() => {
        if (expenseId && list.length > 0 && list !== undefined) {
            const selectedItem: ExpenseType = list.find((row: ExpenseType) => row.id == Number(expenseId)) || {};
            dispatch(setSelectedExpense(selectedItem));
        }
    }, [list, expenseId]);

    const onClickExpenseMenuItem = async (key: string, expense: ExpenseType, closeMenu: Function) => {
        // console.log("Edit Member: ", deposit);
        closeMenu();
        await dispatch(setSelectedExpense(expense));

        switch (key) {
            case 'details':
                navigate(`/expenses/${expense.id}`);
                break;
            case 'edit':
                navigate(`/expenses/edit/${expense.id}`);
                break;
            case 'delete':
                setDeleteConfirmDialog(true);
                break;

            default:
                break;
        }
    }

    const onDeleteConfirmYes = () => {
        setDeleteConfirmDialog(false);
        dispatch(deleteExpense(selectedExpense));
    }

    const onDeleteConfirmNo = () => {
        setDeleteConfirmDialog(false);
    }

    const goExpenseList = async () => {
        await dispatch(resetExpenseAddEditResponse());
        navigate("/expenses");
    }

    const expenseColumns = useMemo<MRT_ColumnDef<ExpenseType>[]>(
        () => [
            {
                accessorKey: 'date', //access nested data with dot notation
                header: 'Date',
            },
            {
                accessorKey: 'title',
                header: 'title',
            },
            {
                accessorKey: 'amount',
                header: 'Amount',
            }
        ],
        [],
    );

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <>
            <Box sx={{ py: { xs: 1, md: 2 } }}>
                <Typography variant="h6" component="h6">
                    List of Expenses
                </Typography>
            </Box>
            <MaterialReactTable
                enablePagination={false}
                enableColumnActions={false}
                initialState={{ columnVisibility: desktop ? {} : { address: false, city: false, state: false } }}
                enableRowActions
                renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem key="details" onClick={() => onClickExpenseMenuItem('details', row.original, closeMenu)}>
                        Details
                    </MenuItem>,
                    <MenuItem key="edit" onClick={() => onClickExpenseMenuItem('edit', row.original, closeMenu)}>
                        Edit
                    </MenuItem>,
                    <MenuItem key="delete" onClick={() => onClickExpenseMenuItem('delete', row.original, closeMenu)}>
                        Delete
                    </MenuItem>,
                ]}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Fab size="small" color="primary" aria-label="add" onClick={() => navigate("/expenses/add")}>
                            <AddIcon />
                        </Fab>
                    </Box>
                )}
                columns={expenseColumns}
                data={list} />
            <ConfirmDialog
                open={deleteConfirmDialog}
                onYes={onDeleteConfirmYes}
                onNo={onDeleteConfirmNo}
                text={`Sure to delete expense tk. ${selectedExpense.amount} ?`} />
            <Drawer
                anchor={'right'}
                open={showSideWindow()}
            >
                <Box sx={{ width: { xs: window.innerWidth, md: 500 } }}>
                    {expenseId && !selectedExpense.id ? <Typography>Loading..</Typography> : <Outlet context={{ goExpenseList }} />}
                </Box>
            </Drawer>
        </>
    );

}
export default Expenses;