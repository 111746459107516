import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ConfirmDialogProps = {
    open: boolean;
    onYes: Function;
    onNo: Function;
    text?: string;
    title?: string;
};

const ConfirmDialog = ({ open, onYes, onNo, title, text }: ConfirmDialogProps) => {
    // const [open, setOpen] = React.useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {title &&
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                }
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {text ? text : "Sure to delete ?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onNo()}>No</Button>
                    <Button onClick={() => onYes()} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ConfirmDialog;