import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState, useTypedDispatch } from "../../app/store";
import { ApiResponseType, HTTP } from "../../services/Api";

type SnackNotificationState = {
    open: boolean;
    messages: Array<string>;
    messageString: String;
    color: 'error' | 'success';
};

// type ApiResponseType = {
//     data: Array<Object>;
//     errors: Object | Array<String> | any;
//     message: String;
//     success: Boolean;
// };

const initialState: SnackNotificationState = {
    open: false,
    messages: [],
    messageString: "",
    color: 'success'
};

export const snackbarNotificationSlice = createSlice({
    // A name, used in action types:
    name: "snackbarNotification",

    // The initial state:
    initialState,

    // An object of "case reducers". 
    // Key names will be used to generate actions:
    reducers: {
        setFromApiResponse: (state, action: PayloadAction<ApiResponseType>) => {
            const responseData = action.payload;

            if (responseData.success) {
                state.messageString = responseData.message;
                state.color = 'success';
            } else {
                const errors = responseData.errors;

                if (typeof errors === 'string') {
                    state.messageString = errors;
                }
                else if (typeof errors === 'object' && errors !== null) {
                    state.messages = Object.values(errors);
                    state.messageString = state.messages.join('\n');
                } else {
                    state.messageString = errors.join('\n');
                }

                state.color = 'error';
            }
            state.open = true;
        },
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        snackNotify: (state, action: PayloadAction<{ messageString: string, color: 'error' | 'success' }>) => {
            state.messageString = action.payload.messageString;
            state.color = action.payload.color;
            state.open = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setFromApiResponse, setOpen, snackNotify } = snackbarNotificationSlice.actions;

export default snackbarNotificationSlice.reducer;