import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  Box,
  TextField,
  Card,
  CardContent,
  Unstable_Grid2 as Grid,
  InputAdornment
} from '@mui/material';
import { RootState, useTypedDispatch } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../utils/HelperFunctions';
import history from '../utils/history';
import { Member } from '../features/members/memberSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { login } from '../features/auth/authThunk';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { token, loading, userData } = useSelector((state: RootState) => state.auth);

  // if (token || getToken()) {
  //   console.log("token:", token, getToken());
  //   // history.push('/');
  //   navigate('/');
  // }

  React.useEffect(() => {
    if (token) {
      console.log("token:", token, getToken());
      // history.push('/');
      navigate('/');
    }
  }, [token]);

  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .required('Mobile number is required')
      .matches(new RegExp('^[1-9]+$'), 'Mobile number must be valid number.')
      .length(9, 'Mobile number must be 9 characters'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters')
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Member>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data: Member) => {
    console.log('Login: ', data);
    await dispatch(login(data));
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={4} mdOffset={4} style={{ padding: '15px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                একতা ভিলা
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <Card>
          <CardContent>
            {/* <Typography>{JSON.stringify(userData)}</Typography>
            <Typography>{token}</Typography> */}
            {/* <TextField fullWidth margin="dense" id="outlined-basic" label="Mobile Number" variant="outlined" />
            <TextField fullWidth margin="dense" id="outlined-basic" label="Password" variant="outlined" />
            <Box sx={{ textAlign: 'right', pt: 1 }}>
              <Button variant="contained" style={{ alignSelf: 'right' }}>Login</Button>
            </Box> */}
            <Grid container spacing={1}>
              <Grid xs={12}>
                <TextField
                  required
                  id="mobile"
                  label="Mobile"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+8801</InputAdornment>,
                  }}
                  {...register('mobile')}
                  error={errors.mobile ? true : false}
                  disabled={loading}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors.mobile?.message}
                </Typography>
              </Grid>
              <Grid xs={12}>
                <TextField
                  required
                  id="password"
                  label="Password"
                  type="password"
                  fullWidth
                  margin="dense"
                  {...register('password')}
                  error={errors.password ? true : false}
                  disabled={loading}
                />
                <Typography variant="inherit" color="textSecondary">
                  {errors?.password?.message}
                </Typography>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                sx={{ ml: 2 }}
                disabled={loading}
              >
                Login
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid >
  );

}
export default Login;