import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    AppBar,
    Button,
    Toolbar,
    Typography,
    Box,
    TextField,
    Card,
    CardContent,
    Unstable_Grid2 as Grid,
    Avatar,
    Tabs,
    Tab
} from '@mui/material';
import { Routes, Route, Outlet, NavLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import MemberPersonal from './MemberPersonal';
import { Member } from '../../features/members/memberSlice';
import MemberDeposits from './MemberDeposits';
import { RootState, useTypedSelector } from '../../app/store';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const a11yProps = (index: number) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const FullWidthTabs = ({ member }: { member: Member }) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    return (
        <Box sx={{ bgcolor: 'background.paper', width: { xs: window.innerWidth, md: 500 }, minHeight: { xs: window.innerHeight - 60, md: window.innerHeight - 80 } }}>

            <Typography variant="h6" align="center" margin="dense" mb={2}>
                Member Details
            </Typography>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
            >
                <Tab label="Personal" {...a11yProps(0)} />
                <Tab label="Accounts" {...a11yProps(1)} />
            </Tabs>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <MemberPersonal member={member} />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <MemberDeposits />
                </TabPanel>
            </SwipeableViews>
        </Box>
    );
}

const MemberDetail = () => {
    const navigate = useNavigate();
    const { memberId } = useParams();
    const location = useLocation();
    const row: Member = location.state?.row;
    const { selectedMember } = useTypedSelector((state: RootState) => state.members);

    return (
        <>
            <FullWidthTabs member={selectedMember} />
            <Box sx={{ textAlign: 'center' }}>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => navigate("/members")}
                >
                    Close
                </Button>
            </Box>
        </>
    );

}
export default MemberDetail;