import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    AppBar,
    Button,
    Toolbar,
    Typography,
    Box,
    TextField,
    Card,
    CardContent,
    Unstable_Grid2 as Grid,
    Paper,
    FormControlLabel,
    Checkbox,
    InputAdornment
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useNavigate } from 'react-router-dom';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { HTTP } from '../../services/Api';
import { Member, addMember, resetAddMemberResponse } from '../../features/members/memberSlice';
import { RootState, useTypedDispatch } from '../../app/store';
import { useSelector } from 'react-redux';

type FormValuesType = {
    full_name: string;
    mobile: string;
    email: string;
    password: string;
};

const MemberAdd = () => {
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();

    const { isMemberAdding, addMemberResponse } = useSelector((state: RootState) => state.members);

    const validationSchema = Yup.object().shape({
        full_name: Yup.string()
            .required('Fullname is required')
            .min(3, 'Fullname must be at least 3 characters')
            .max(20, 'Fullname must not exceed 255 characters'),
        mobile: Yup.string()
            .required('Mobile number is required')
            .matches(new RegExp('^[1-9]+$'), 'Mobile number must be valid number.')
            .length(9, 'Mobile number must be 9 characters'),
        email: Yup.string()
            .email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(40, 'Password must not exceed 40 characters'),
        role: Yup.string()
            .required('Member role is required')
    });

    const {
        register,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<Member>({
        resolver: yupResolver(validationSchema)
    });

    const goMemberList = async () => {
        await dispatch(resetAddMemberResponse());
        navigate("/members");
    }

    const onSubmit = async (data: Member) => {
        await dispatch(addMember(data));
    };

    React.useEffect(() => {
        if (addMemberResponse.success === true) {
            goMemberList();
        }
    }, [addMemberResponse]);

    return (
        <>
            <Box px={3} py={2}>
                <Typography variant="h6" align="center" margin="dense" mb={2}>
                    Add New Member
                </Typography>
                {/* <Typography variant="h6" align="center" margin="dense" mb={2}>
                    {JSON.stringify(addMemberResponse)}
                </Typography> */}

                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <TextField
                            required
                            id="full_name"
                            label="Full Name"
                            fullWidth
                            margin="dense"
                            {...register('full_name')}
                            error={errors.full_name ? true : false}
                            disabled={isMemberAdding}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.full_name?.message}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            required
                            id="mobile"
                            label="Mobile"
                            fullWidth
                            margin="dense"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+8801</InputAdornment>,
                            }}
                            {...register('mobile')}
                            error={errors.mobile ? true : false}
                            disabled={isMemberAdding}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.mobile?.message}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            id="email"
                            label="Email"
                            fullWidth
                            margin="dense"
                            {...register('email')}
                            error={errors.email ? true : false}
                            disabled={isMemberAdding}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.email?.message}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            required
                            id="password"
                            label="Password"
                            type="password"
                            fullWidth
                            margin="dense"
                            {...register('password')}
                            error={errors.password ? true : false}
                            disabled={isMemberAdding}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors?.password?.message}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="user-role">Member Role</InputLabel>
                            <Select
                                labelId="user-role-label"
                                id="role"
                                label="Member Role"
                                {...register('role')}
                                error={errors.role ? true : false}
                                disabled={isMemberAdding}
                            >
                                <MenuItem value="admin">Administrator</MenuItem>
                                <MenuItem value="accountant">Accountant</MenuItem>
                                <MenuItem value="member">General Member</MenuItem>
                            </Select>
                        </FormControl>
                        <Typography variant="inherit" color="textSecondary">
                            {errors?.role?.message}
                        </Typography>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => goMemberList()}
                        disabled={isMemberAdding}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        sx={{ ml: 2 }}
                        disabled={isMemberAdding}
                    >
                        Add Member
                    </Button>
                </Box>
            </Box>
        </>
    );

}
export default MemberAdd;