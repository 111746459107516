import { createAsyncThunk } from '@reduxjs/toolkit'
import { getToken, removeToken, setToken } from '../../utils/HelperFunctions';
import { HTTP } from '../../services/Api';
import history from '../../utils/history';
import { Member } from '../members/memberSlice';
// import api from '../../services/api';
// import history from '../../utils/history';


export const fetchUserData = createAsyncThunk('auth/fetchUserData', async (_, { rejectWithValue }) => {
    try {
        const token = getToken();
        // api.defaults.headers.Authorization = `Bearer ${accessToken}`;
        // const response = await api.get('/user');
        const response = await HTTP.get('/logged_user');
        return { user: response.data, token };
    } catch (e) {
        removeToken();
        return rejectWithValue('');
    }
});

export const login = createAsyncThunk('auth/login', async (data: Member) => {
    // history.push('/');
    // const response = await api.post('/login', payload);
    const response = await HTTP.post('/auth', data);
    setToken(response.data.data.token);
    // history.push('/home');
    // history.push('/');
    return response.data.data;
});

export const signOut = createAsyncThunk('auth/signOut', async () => {
    removeToken();
});