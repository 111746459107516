import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    AppBar,
    Button,
    Toolbar,
    Typography,
    Box,
    TextField,
    Card,
    CardContent,
    Unstable_Grid2 as Grid,
    Paper,
    FormControlLabel,
    Checkbox,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Member, resetAddMemberResponse, updateMember } from '../../features/members/memberSlice';
import { RootState, useTypedDispatch } from '../../app/store';
import { useSelector } from 'react-redux';

const MemberEdit = () => {
    const { memberId } = useParams();
    const location = useLocation();
    // console.log('state data:', location);
    const row: Member = location.state?.row;
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();
    const { isMemberUpdating, updateMemberResponse } = useSelector((state: RootState) => state.members);

    const validationSchema = Yup.object().shape({
        full_name: Yup.string()
            .required('Fullname is required')
            .min(3, 'Fullname must be at least 3 characters')
            .max(20, 'Fullname must not exceed 255 characters'),
        mobile: Yup.string()
            .required('Mobile number is required')
            .matches(new RegExp('^[1-9]+$'), 'Mobile number must be valid number.')
            .length(9, 'Mobile number must be 9 characters'),
        email: Yup.string()
            .email('Email is invalid'),
        role: Yup.string()
            .required('Member role is required.')
    });

    const {
        register,
        control,
        handleSubmit,
        formState: { errors, isDirty, dirtyFields }
    } = useForm<Member>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            full_name: row.full_name,
            mobile: row.mobile,
            email: row.email,
            role: row.role
        }
    });

    const goMemberList = async () => {
        await dispatch(resetAddMemberResponse());
        navigate("/members");
    }

    const onSubmit = async (data: Member) => {
        // console.log(JSON.stringify(data, null, 2));
        await dispatch(updateMember({ id: row.id, ...data }));
    };

    React.useEffect(() => {
        if (updateMemberResponse.success === true) {
            goMemberList();
        }
    }, [updateMemberResponse]);

    return (
        <>
            <Box px={3} py={2}>
                <Typography variant="h6" align="center" margin="dense" mb={2}>
                    Edit - {row.full_name}
                </Typography>

                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <TextField
                            required
                            id="full_name"
                            label="Full Name"
                            fullWidth
                            margin="dense"
                            {...register('full_name')}
                            error={errors.full_name ? true : false}
                            disabled={isMemberUpdating}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.full_name?.message}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            required
                            id="mobile"
                            label="Mobile"
                            fullWidth
                            margin="dense"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+8801</InputAdornment>,
                            }}
                            {...register('mobile')}
                            error={errors.mobile ? true : false}
                            disabled={isMemberUpdating}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.mobile?.message}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            id="email"
                            label="Email"
                            fullWidth
                            margin="dense"
                            {...register('email')}
                            error={errors.email ? true : false}
                            disabled={isMemberUpdating}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.email?.message}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="user-role">Member Role</InputLabel>
                            <Select
                                defaultValue={row.role}
                                labelId="user-role-label"
                                id="role"
                                label="Member Role"
                                {...register('role')}
                                error={errors.role ? true : false}
                                disabled={isMemberUpdating}
                            >
                                <MenuItem value="admin">Administrator</MenuItem>
                                <MenuItem value="accountant">Accountant</MenuItem>
                                <MenuItem value="member">General Member</MenuItem>
                            </Select>
                        </FormControl>
                        <Typography variant="inherit" color="textSecondary">
                            {errors?.role?.message}
                        </Typography>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => goMemberList()}
                        disabled={isMemberUpdating}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        sx={{ ml: 2 }}
                        disabled={!isDirty || isMemberUpdating}
                    >
                        Update Member
                    </Button>
                </Box>
            </Box>
        </>
    );

}
export default MemberEdit;