import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Unstable_Grid2 as Grid,
    Avatar,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    Divider,
    Stack,
    Typography,
    Toolbar,
    IconButton,
    Paper
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { Routes, Route, Outlet, NavLink, useParams, useNavigate, useOutletContext } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { Member, fetchMembers, selectMembers, setMemberList, setSelectedMember } from '../../features/members/memberSlice';
import { upload, upload1, uploadFileURL } from '../../services/UploadApi';
import { RootState, useTypedDispatch, useTypedSelector } from '../../app/store';
import { useSelector } from 'react-redux';
import RelatedFiles from '../File/RelatedFiles';
import { dateFormat } from '../../utils/HelperFunctions';

const ExpenseDetails = () => {
    const dispatch = useTypedDispatch();
    const members = useTypedSelector(selectMembers);
    const { selectedExpense } = useTypedSelector((state: RootState) => state.expenses);
    const { selectedDeposit } = useSelector((state: RootState) => state.deposits);
    const { goExpenseList } = useOutletContext<{ goExpenseList: Function }>();

    return (
        <React.Fragment>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => goExpenseList()}
                >
                    <ArrowBackIosOutlinedIcon />
                </IconButton>
                <Typography variant="h6" component="div" align='center' sx={{ flexGrow: 1 }}>
                    Expense Details
                </Typography>
            </Toolbar>

            <List sx={{ width: '95%', bgcolor: 'background.paper', paddingX: 1 }}>
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={selectedExpense.category_name}
                        secondary={"Category"}
                    />
                </ListItem>
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={selectedExpense.title}
                        secondary={"Title"}
                    />
                </ListItem>
                <Divider variant="fullWidth" component="li" />
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={dateFormat(selectedExpense.date)}
                        secondary={"Date"}
                    />
                </ListItem>
                <Divider variant="fullWidth" component="li" />
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={`Tk. ${selectedExpense.amount}`}
                        secondary={"Amount"}
                    />
                </ListItem>
            </List>
            <RelatedFiles
                title='Attached Files'
                ofName='expenses'
                offID={selectedExpense.id || 0} />
        </React.Fragment >
    );
}
export default ExpenseDetails;