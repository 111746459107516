import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ApiResponseType, HTTP } from "../../services/Api";
import { setFromApiResponse } from "../notification/snackbarNotificationSlice";

export type ExpenseCategoryType = {
    id?: number;
    name?: string;
    created_date?: string;
    updated_date?: string;
    created_by?: number;
    updated_by?: number;
    category_name?: string;
};

type ExpenseCategoryState = {
    list: ExpenseCategoryType[];
    isLoading: boolean;
    error: string | null | undefined;

    isExpenseCategoryAdding: boolean;
    isExpenseCategoryUpdating: boolean;
    addExpenseCategoryResponse: ApiResponseType;
    updateExpenseCategoryResponse: ApiResponseType;

    selectedExpenseCategory: ExpenseCategoryType;
};

const addMemberResponseDefault = { data: [], errors: {}, message: "", success: false };

const initialState: ExpenseCategoryState = {
    list: [],
    isLoading: false,
    error: null,
    isExpenseCategoryAdding: false,
    isExpenseCategoryUpdating: false,
    addExpenseCategoryResponse: addMemberResponseDefault,
    updateExpenseCategoryResponse: addMemberResponseDefault,
    selectedExpenseCategory: {}
};



export const fetchExpenseCategories = createAsyncThunk("expenseCategories/fetchExpenseCategories", async () => {
    const res = await HTTP.get('/expense_categories');
    return res.data.data;
});

export const addExpenseCategory = createAsyncThunk("expenseCategories/addExpenseCategory", async (data: ExpenseCategoryType, { dispatch }) => {
    const res = await HTTP.post('/expense_category', data);
    await dispatch(fetchExpenseCategories());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const updateExpenseCategory = createAsyncThunk("expenseCategories/updateExpenseCategory", async (data: ExpenseCategoryType, { dispatch }) => {
    const res = await HTTP.put('/expense_category', data);
    await dispatch(fetchExpenseCategories());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const deleteExpenseCategory = createAsyncThunk("expenseCategories/deleteExpenseCategory", async (data: ExpenseCategoryType, { dispatch }) => {
    const res = await HTTP.delete('/expense_category', { id: data.id });
    await dispatch(fetchExpenseCategories());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const expenseCategorySlice = createSlice({
    // A name, used in action types:
    name: "expenseCategories",

    // The initial state:
    initialState,

    // An object of "case reducers". 
    // Key names will be used to generate actions:
    reducers: {
        resetExpenseCategoryAddEditResponse: (state) => {
            state.addExpenseCategoryResponse = addMemberResponseDefault;
            state.updateExpenseCategoryResponse = addMemberResponseDefault;
        },
        setSelectedExpenseCategory: (state, action: PayloadAction<ExpenseCategoryType>) => {
            state.selectedExpenseCategory = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchExpenseCategories.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchExpenseCategories.fulfilled, (state, action) => {
            state.isLoading = false;
            state.list = action.payload;
        });
        builder.addCase(fetchExpenseCategories.rejected, (state, action) => {
            state.isLoading = false;
            state.list = [];
            state.error = action.error.message;
        });
        builder.addCase(addExpenseCategory.pending, (state, action) => {
            state.isExpenseCategoryAdding = true;
        });
        builder.addCase(addExpenseCategory.fulfilled, (state, action) => {
            state.addExpenseCategoryResponse = action.payload;
            state.isExpenseCategoryAdding = false;
        });
        builder.addCase(addExpenseCategory.rejected, (state, action) => {
            state.isExpenseCategoryAdding = false;
        });
        builder.addCase(updateExpenseCategory.pending, (state, action) => {
            state.isExpenseCategoryUpdating = true;
        });
        builder.addCase(updateExpenseCategory.fulfilled, (state, action) => {
            state.updateExpenseCategoryResponse = action.payload;
            state.isExpenseCategoryUpdating = false;
        });
        builder.addCase(updateExpenseCategory.rejected, (state, action) => {
            state.isExpenseCategoryUpdating = false;
        });
    },
});

// Export all of the actions:
export const { resetExpenseCategoryAddEditResponse, setSelectedExpenseCategory } = expenseCategorySlice.actions;

// It is a convention to export reducer as a default export:
export default expenseCategorySlice.reducer;

// Create and export the selector:
// export const selectMembers = (state: RootState) => state.members.list;