import queryString from 'query-string';
// import md5 from "md5";
import axios from 'axios';
import { getToken } from '../utils/HelperFunctions';
// import AsyncStorage from '@react-native-community/async-storage';
// import { AsyncStorage } from 'react-native';
// import { store } from '../store/configureStore';
// import { useNavigation } from '@react-navigation/native';
// import * as NavigationService from '../NavigationService';
// import { setLoggedData } from '../actions';

// export const API_SERVER = `http://localhost/share-building-api`;
export const API_SERVER = `https://akota-api.bitsbd.com`;
axios.defaults.baseURL = `${API_SERVER}/index.php/api`;

export type ApiResponseType = {
  data: Array<Object> | Object | any;
  errors: Object | Array<String> | any;
  message: String;
  success: Boolean;
};


// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // const logged = store.getState().persisted.logged;
    // const authToken = logged && logged.token ? 'Bearer ' + logged.token : '';
    const authToken = getToken();
    config.headers.Authorization = `Bearer ${authToken}`;

    // console.log('auth token: ', authToken);
    console.log('config: ', config);

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

/*
axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    console.log('response: ', response);
    return response;
  },
  function(error) {
    // Do something with response error
    console.log('response error: ', error);
    console.log('response error: ', error.response);

    if (error.response.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);
*/

const client = axios;

const toFormData = (obj: object) => {
  return queryString.stringify(obj);
};

const getFormData = (object: object) => {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key as keyof object]));
  return formData;
}

const post = (uri: string, data: object) => {
  return client.post(uri, toFormData(data));
};

const put = (uri: string, data: object) => {
  return client.put(uri, toFormData(data));
};

const get = (uri: string, data = {}) => {
  return client.get(uri, {
    params: data,
  });
};

const del = (uri: string, data: { id?: number }) => {
  const { id } = data;
  return client.delete(`${uri}/${id}`);
};

export const HTTP = {
  client,
  get,
  post,
  put,
  delete: del
};