import * as React from 'react';
import {
    Button,
    Typography,
    Box,
    TextField,
    Unstable_Grid2 as Grid,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Stack
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useNavigate, useOutletContext } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Member, fetchMembers } from '../../features/members/memberSlice';
import { RootState, useTypedDispatch, useTypedSelector } from '../../app/store';
import { useSelector } from 'react-redux';
import { DepositType, addDeposit, resetDepositAddEditResponse } from '../../features/deposits/depositSlice';
import { DATE_FORMAT, MYSQL_DATE_FORMAT, TEXT_MAX_LENGTH, VARCHAR_MAX_LENGTH } from '../../constants/settings';
import { ExpenseType, addExpense, fetchAndSetSelectedExpense, setSelectedExpense, updateExpense } from '../../features/expenses/expenseSlice';
import { ExpenseCategoryType, fetchExpenseCategories } from '../../features/expense_cetegories/expenseCategorySlice';
import { dateFormat } from '../../utils/HelperFunctions';
import SideWindowHeader from '../Common/SideWindowHeader';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ExpenseEdit = () => {
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();

    const { isExpenseAdding, addExpenseResponse, isExpenseUpdating, selectedExpense, updateExpenseResponse } = useTypedSelector((state: RootState) => state.expenses);
    const expenseCategories = useTypedSelector((state: RootState) => state.expenseCategories.list);

    const { goExpenseList } = useOutletContext<{ goExpenseList: Function }>();

    const validationSchema = Yup.object().shape({
        category_id: Yup.number()
            .required('Category is required'),
        date: Yup.date().default(() => new Date()),
        amount: Yup.number()
            .required('Expense amount is required')
            .min(1, 'Wrong expense amount.'),
        title: Yup.string()
            .required("Expense title required.")
            .max(VARCHAR_MAX_LENGTH),
        description: Yup.string()
            .required("Expense description required.")
            .max(TEXT_MAX_LENGTH),
    });

    const {
        register,
        control,
        handleSubmit,
        formState: { errors, isValid, isDirty }
    } = useForm<ExpenseType>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            category_id: selectedExpense.category_id,
            date: selectedExpense.date,
            amount: selectedExpense.amount,
            title: selectedExpense.title,
            description: selectedExpense.description
        }
    });

    const onSubmit = async (data: ExpenseType) => {
        const formatedData = {
            ...data,
            date: dateFormat(data.date, MYSQL_DATE_FORMAT),
            id: selectedExpense.id
        };
        await dispatch(updateExpense(formatedData));

    };

    React.useEffect(() => {
        dispatch(fetchExpenseCategories());
    }, []);

    React.useEffect(() => {
        if (updateExpenseResponse.success === true) {
            // const newExpenseId = addExpenseResponse.data.new_item_id;
            if (selectedExpense.id) {
                dispatch(fetchAndSetSelectedExpense(selectedExpense.id));
                navigate(`/expenses/${selectedExpense.id}`);
            }
        }
    }, [updateExpenseResponse]);

    return (
        <>
            <SideWindowHeader title='Edit Expense' onClickBack={goExpenseList} />
            <Box px={3} py={2}>
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <TextField
                            id="title"
                            label="Expense Title"
                            fullWidth
                            margin="dense"
                            {...register('title')}
                            error={errors.title ? true : false}
                            disabled={isExpenseUpdating}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.title?.message}
                        </Typography>
                    </Grid>

                    <Grid xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="user-role">Expense Category</InputLabel>
                            <Select
                                labelId="user-role-label"
                                id="category_id"
                                label="Expense Category"
                                {...register('category_id')}
                                error={errors.category_id ? true : false}
                                disabled={isExpenseUpdating}
                                MenuProps={MenuProps}
                                defaultValue={selectedExpense.category_id}
                            >
                                {expenseCategories.map((row: ExpenseCategoryType) => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <Typography variant="inherit" color="textSecondary">
                            {errors.category_id?.message}
                        </Typography>
                    </Grid>

                    <Grid xs={12}>
                        <Controller
                            name="date"
                            control={control}
                            defaultValue=""
                            render={({ field, ...props }) => {
                                return (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                defaultValue={dayjs(selectedExpense.date)}
                                                label="Expense Date"
                                                format={DATE_FORMAT}
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: errors.date ? true : false
                                                    },
                                                }}
                                                disabled={isExpenseUpdating} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                );
                            }}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.date?.message}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            id="amount"
                            label="Amount"
                            fullWidth
                            margin="dense"
                            {...register('amount')}
                            error={errors.amount ? true : false}
                            disabled={isExpenseUpdating}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.amount?.message}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            id="description"
                            label="Description"
                            fullWidth
                            margin="dense"
                            {...register('description')}
                            error={errors.description ? true : false}
                            disabled={isExpenseUpdating}
                            multiline
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.description?.message}
                        </Typography>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => goExpenseList()}
                        disabled={isExpenseUpdating}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        sx={{ ml: 2 }}
                        disabled={isExpenseUpdating || !isDirty}
                    >
                        Update Expense
                    </Button>
                </Box>
            </Box>
        </>
    );

}
export default ExpenseEdit;