import { createSlice } from '@reduxjs/toolkit'
import {fetchUserData, login, signOut} from './authThunk';
import { Member, MemberRole } from '../members/memberSlice';

type AuthState = {
    token: String | null;
    loading: boolean;
    userData: Member;
};

export const defaultMemberData = {role: MemberRole.member};

const initialState: AuthState = {
    token: null,
    loading: false,
    userData: defaultMemberData
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},    
    extraReducers(builder) {
        builder.addCase(signOut.fulfilled, (state) => {
            state.loading = false;
            state.userData = defaultMemberData;
            state.token = null;
        });
        builder.addCase(login.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            const {token, user} = action.payload;
            state.token = token;
            state.userData = user;
            state.loading = false;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(fetchUserData.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            const {token, user} = action.payload;
            state.token = token;
            state.userData = user;
            state.loading = false;
        });
        builder.addCase(fetchUserData.rejected, (state, action) => {
            state.loading = false;
            state.userData = defaultMemberData;
            state.token = null;
        });
    },
})


export const {} = authSlice.actions;

export default authSlice.reducer;