import * as React from 'react';
import {
    Button,
    Typography,
    Box,
    TextField,
    Unstable_Grid2 as Grid,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Stack
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Member, fetchMembers } from '../../features/members/memberSlice';
import { RootState, useTypedDispatch } from '../../app/store';
import { useSelector } from 'react-redux';
import { DepositType, addDeposit, resetDepositAddEditResponse } from '../../features/deposits/depositSlice';

const DepositAdd = () => {
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();

    const { isDepositAdding, addDepositResponse } = useSelector((state: RootState) => state.deposits);
    const members = useSelector((state: RootState) => state.members.list);

    const validationSchema = Yup.object().shape({
        member_id: Yup.number()
            .required('Member is required'),
        date: Yup.date().default(() => new Date()),
        amount: Yup.number()
            .required('Deposit amount is required')
            .min(1, 'Wrong deposit amount.')
    });

    const {
        register,
        control,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm<DepositType>({
        resolver: yupResolver(validationSchema)
    });

    const goDepositList = async () => {
        await dispatch(resetDepositAddEditResponse());
        navigate("/deposits");
    }

    const onSubmit = async (data: DepositType) => {
        // console.log(data);
        const formatedData = { ...data, date: dayjs(data.date).format("YYYY-MM-DD") };
        // console.log('formatedData', formatedData);
        await dispatch(addDeposit(formatedData));
    };

    React.useEffect(() => {
        dispatch(fetchMembers());
    }, []);

    React.useEffect(() => {
        if (addDepositResponse.success === true) {
            goDepositList();
        }
    }, [addDepositResponse]);

    return (
        <>
            <Box px={3} py={2}>
                <Typography variant="h6" align="center" margin="dense" mb={2}>
                    Add New Deposit
                </Typography>
                {/* <Typography variant="h6" align="center" margin="dense" mb={2}>
                    {JSON.stringify(addMemberResponse)}
                </Typography> */}

                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="user-role">Member</InputLabel>
                            <Select
                                labelId="user-role-label"
                                id="member_id"
                                label="Member"
                                {...register('member_id')}
                                error={errors.member_id ? true : false}
                                disabled={isDepositAdding}
                                renderValue={(value: any) => <Typography>{members.find((row: Member) => row.id == Number(value))?.full_name}</Typography>}
                            >
                                {members.map((row: Member) => <MenuItem key={row.id} value={row.id}>
                                    <Stack direction="row">
                                        <ListItemAvatar>
                                            <Avatar alt={row.full_name} src={row.photo} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={row.full_name}
                                            secondary={row.mobile}
                                        />
                                    </Stack></MenuItem>)}
                            </Select>
                        </FormControl>
                        <Typography variant="inherit" color="textSecondary">
                            {errors.member_id?.message}
                        </Typography>
                    </Grid>

                    <Grid xs={12}>
                        <Controller
                            name="date"
                            control={control}
                            defaultValue=""
                            render={({ field, ...props }) => {
                                return (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                label="Deposit Date"
                                                format="DD/MM/YYYY"
                                                onChange={(date) => {
                                                    console.log({ date });
                                                    field.onChange(date);
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: errors.date ? true : false
                                                    },
                                                }}
                                                disabled={isDepositAdding} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                );
                            }}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.date?.message}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <TextField
                            id="amount"
                            label="Amount"
                            fullWidth
                            margin="dense"
                            {...register('amount')}
                            error={errors.amount ? true : false}
                            disabled={isDepositAdding}
                        />
                        <Typography variant="inherit" color="textSecondary">
                            {errors.amount?.message}
                        </Typography>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => goDepositList()}
                        disabled={isDepositAdding}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        sx={{ ml: 2 }}
                        disabled={isDepositAdding || !isValid}
                    >
                        Add Deposit
                    </Button>
                </Box>
            </Box>
        </>
    );

}
export default DepositAdd;