import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ApiResponseType, HTTP } from "../../services/Api";
import { setFromApiResponse } from "../notification/snackbarNotificationSlice";

export type ExpenseType = {
    id?: number;
    category_id?: number;
    title?: string;
    description?: string;
    date?: string;
    amount?: number;
    created_date?: string;
    updated_date?: string;
    created_by?: number;
    updated_by?: number;
    category_name?: string;
};

type ExpenseState = {
    list: ExpenseType[];
    isLoading: boolean;
    error: string | null | undefined;

    isExpenseAdding: boolean;
    isExpenseUpdating: boolean;
    addExpenseResponse: ApiResponseType;
    updateExpenseResponse: ApiResponseType;

    selectedExpense: ExpenseType;
};

const addMemberResponseDefault = { data: [], errors: {}, message: "", success: false };

const initialState: ExpenseState = {
    list: [],
    isLoading: false,
    error: null,
    isExpenseAdding: false,
    isExpenseUpdating: false,
    addExpenseResponse: addMemberResponseDefault,
    updateExpenseResponse: addMemberResponseDefault,
    selectedExpense: {}
};



export const fetchAndSetSelectedExpense = createAsyncThunk("expenses/fetchAndSetSelectedExpense", async (expenseId: number) => {
    const res = await HTTP.get(`/expenses/${expenseId}`);
    return res.data.data;
});

export const fetchExpenses = createAsyncThunk("expenses/fetchExpenses", async () => {
    const res = await HTTP.get('/expenses');
    return res.data.data;
});

export const addExpense = createAsyncThunk("expenses/addExpense", async (data: ExpenseType, { dispatch }) => {
    const res = await HTTP.post('/expense', data);
    await dispatch(fetchExpenses());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const updateExpense = createAsyncThunk("expenses/updateExpense", async (data: ExpenseType, { dispatch }) => {
    const res = await HTTP.put('/expense', data);
    await dispatch(fetchExpenses());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const deleteExpense = createAsyncThunk("expenses/deleteExpense", async (data: ExpenseType, { dispatch }) => {
    const res = await HTTP.delete('/expense', { id: data.id });
    await dispatch(fetchExpenses());
    await dispatch(setFromApiResponse(res.data));
    return res.data;
});

export const expenseSlice = createSlice({
    // A name, used in action types:
    name: "expenses",

    // The initial state:
    initialState,

    // An object of "case reducers". 
    // Key names will be used to generate actions:
    reducers: {
        resetExpenseAddEditResponse: (state) => {
            state.addExpenseResponse = addMemberResponseDefault;
            state.updateExpenseResponse = addMemberResponseDefault;
        },
        setSelectedExpense: (state, action: PayloadAction<ExpenseType>) => {
            state.selectedExpense = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchExpenses.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchExpenses.fulfilled, (state, action) => {
            state.isLoading = false;
            state.list = action.payload;
        });
        builder.addCase(fetchExpenses.rejected, (state, action) => {
            state.isLoading = false;
            state.list = [];
            state.error = action.error.message;
        });
        builder.addCase(addExpense.pending, (state, action) => {
            state.isExpenseAdding = true;
        });
        builder.addCase(addExpense.fulfilled, (state, action) => {
            state.addExpenseResponse = action.payload;
            state.isExpenseAdding = false;
        });
        builder.addCase(addExpense.rejected, (state, action) => {
            state.isExpenseAdding = false;
        });
        builder.addCase(updateExpense.pending, (state, action) => {
            state.isExpenseUpdating = true;
        });
        builder.addCase(updateExpense.fulfilled, (state, action) => {
            state.updateExpenseResponse = action.payload;
            state.isExpenseUpdating = false;
        });
        builder.addCase(updateExpense.rejected, (state, action) => {
            state.isExpenseUpdating = false;
        });
        builder.addCase(fetchAndSetSelectedExpense.fulfilled, (state, action) => {
            state.selectedExpense = action.payload;
        });
    },
});

// Export all of the actions:
export const { resetExpenseAddEditResponse, setSelectedExpense } = expenseSlice.actions;

// It is a convention to export reducer as a default export:
export default expenseSlice.reducer;

// Create and export the selector:
// export const selectMembers = (state: RootState) => state.members.list;