import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    AppBar,
    Button,
    Toolbar,
    Typography,
    Box,
    TextField,
    IconButton,
    ListItemText,
    Unstable_Grid2 as Grid,
    Paper,
    ListItem,
    List,
    ListItemButton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Close';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { FileType, deleteFile, getFiles, upload, viewFileNewTab } from '../../services/UploadApi';
import { setFromApiResponse, snackNotify } from '../../features/notification/snackbarNotificationSlice';
import { useDispatch } from 'react-redux';
import { useTypedDispatch } from '../../app/store';
import ConfirmDialog from '../Common/ConfirmDialog';

type RelatedFilesProps = {
    title?: string;
    ofName: string;
    offID: number;
};

const RelatedFiles = ({ title = "Related Files", ofName, offID }: RelatedFilesProps) => {
    const [files, setFiles] = useState<FileType[]>([]);
    const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<FileType>({});
    const [uploading, setUploading] = useState<boolean>(false);

    const dispatch = useTypedDispatch();

    const fetchFiles = async () => {
        const fileRes = await getFiles({ of_id: offID, of_name: ofName });
        setFiles(fileRes.data.data);
    }

    useEffect(() => {
        fetchFiles();
    }, []);

    const handleListItemClick = async (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        file: FileType,
    ) => {
        var element = event.target as HTMLElement;
        // console.log(element);
        if (['path', 'svg', 'button'].includes(element.tagName)) {
            // console.log('Delete');
            setSelectedFile(file);
            setDeleteConfirmDialog(true);

        } else {
            viewFileNewTab(file);
        }
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setUploading(true);
        const uploaded = await upload(event.target, {
            of_name: ofName,
            of_id: offID
        });
        setUploading(false);

        if (uploaded) {
            await dispatch(setFromApiResponse(uploaded.data));
            if (uploaded.data.success === true) {
                fetchFiles();
            }
        }
    }

    const onDeleteConfirmYes = async () => {
        setDeleteConfirmDialog(false);

        const deleted = await deleteFile(selectedFile);
        if (deleted) {
            await dispatch(setFromApiResponse(deleted.data));
            if (deleted.data.success === true) {
                fetchFiles();
            }
        } else {
            dispatch(snackNotify({ messageString: "File delete failed.", color: 'error' }));
        }
    }

    const onDeleteConfirmNo = () => {
        setDeleteConfirmDialog(false);
    }

    return (
        <>
            <Paper>
                <Typography sx={{ mx: 2, mt: 4 }} variant="h6" component="div">
                    {title}
                </Typography>
                <List dense={true} component="nav">
                    {files.map(file => (
                        <ListItemButton
                            key={file.id}
                            onClick={(event) => handleListItemClick(event, file)}
                            sx={{pl:0}}
                        >
                            <ListItem
                                secondaryAction={
                                    <IconButton size='small' edge="end" aria-label="delete" title='Delete file' color='warning'>
                                        <DeleteIcon />
                                    </IconButton>
                                }>
                                <ListItemText
                                    primary={file.client_name}
                                />
                            </ListItem>
                        </ListItemButton>
                    ))}
                </List>
                <LoadingButton loading={uploading} loadingPosition='start' color="primary" aria-label="Add new file" component="label" sx={{ ml: 3, mb: 1 }} size="small" startIcon={<AddCircleOutlineOutlinedIcon />}>
                    <input hidden type="file" onChange={handleFileUpload} />
                    Add File
                </LoadingButton>
            </Paper>

            <ConfirmDialog
                open={deleteConfirmDialog}
                onYes={onDeleteConfirmYes}
                onNo={onDeleteConfirmNo}
                text={`Sure to delete ${selectedFile.client_name} ?`} />
        </>
    );
}

export default RelatedFiles;