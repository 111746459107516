import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  Box,
  TextField,
  Card,
  CardContent,
  Unstable_Grid2 as Grid,
  Avatar
} from '@mui/material';
import { Routes, Route, Outlet, NavLink } from 'react-router-dom';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { blue } from '@mui/material/colors';

const DASHBOART_ITEMS = [
  {
    key: 'account-summary',
    icon: PeopleAltOutlinedIcon,
    label: 'Accounts',
    routePath: '/account-summary',
  },
  {
    key: 'members',
    icon: PeopleAltOutlinedIcon,
    label: 'Members',
    routePath: '/members',
  },
  {
    key: 'deposits',
    icon: AddCircleOutlineOutlinedIcon,
    label: 'Deposits',
    routePath: '/deposits',
  },
  {
    key: 'expenses',
    icon: RemoveCircleOutlineOutlinedIcon,
    label: 'Expenses',
    routePath: '/expenses',
  }
];

const Dashboard = () => {

  return (
    <Grid container spacing={{ xs: 2, md: 4 }} minHeight={160} mt={{ xs: 2, md: 3 }}>
      {DASHBOART_ITEMS.map((item, index) => (
        <Grid xs={4} sm={3} md={2} display="flex" justifyContent="center" alignItems="center" key={index}>
          <NavLink to={item.routePath} style={{textDecoration: 'none'}}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar alt={item.label} sx={{ bgcolor: blue[700] }}>
                <item.icon />
              </Avatar>
              <Typography variant="button">
                {item.label}
              </Typography>
            </Grid>
          </NavLink>
        </Grid>
      ))}
    </Grid>
  );

}
export default Dashboard;