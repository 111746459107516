import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    AppBar,
    Button,
    Toolbar,
    Typography,
    Box,
    TextField,
    Card,
    CardContent,
    Unstable_Grid2 as Grid,
    Avatar,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    Divider,
    Stack,
    IconButton
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Routes, Route, Outlet, NavLink, useParams, useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { Member, fetchMembers, selectMembers, setMemberList, setSelectedMember } from '../../features/members/memberSlice';
import { upload, upload1, uploadFileURL } from '../../services/UploadApi';
import { RootState, useTypedDispatch, useTypedSelector } from '../../app/store';
import { useSelector } from 'react-redux';

const MemberPersonal = ({ member }: { member: Member }) => {
    const dispatch = useTypedDispatch();
    const members = useTypedSelector(selectMembers);
    const { selectedMember } = useSelector((state: RootState) => state.members);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // const res = await upload(event.target, {
        //     of_name: "users",
        //     of_id: selectedMember.id,
        //     old_file_name: ''
        // });

        const res = await upload1(event.target, {
            of_name: "users",
            of_field: "photo",
            of_id: selectedMember.id,
        });

        if (!res) {
            return false;
        }

        dispatch(setSelectedMember({ ...selectedMember, photo: uploadFileURL(res.data.data.file_name) }));
        dispatch(fetchMembers());
    }

    return (
        <React.Fragment>
            <Stack direction="column" spacing={2}>
                {/* {selectedMember.photo} */}
                {/* {JSON.stringify(members.map((row) => row.photo))} */}
                <Avatar
                    alt={selectedMember.full_name}
                    src={selectedMember.photo}
                    sx={{ width: 130, height: 130, alignSelf: 'center' }}
                />
                <IconButton color="primary" aria-label="upload picture" component="label" sx={{ padding: 0, margin: 0 }} size="small">
                    <input hidden accept="image/*" type="file" onChange={handleFileUpload} />
                    <PhotoCamera />
                </IconButton>
            </Stack>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={selectedMember.full_name}
                        secondary={"Full Name"}
                    />
                </ListItem>
                <Divider variant="fullWidth" component="li" />
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={selectedMember.mobile}
                        secondary={"Mobile"}
                    />
                </ListItem>
                <Divider variant="fullWidth" component="li" />
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={selectedMember.email}
                        secondary={"Email"}
                    />
                </ListItem>
            </List>
        </React.Fragment >
    );
}
export default MemberPersonal;