import * as React from 'react';
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppDispatch, RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { setOpen } from '../../features/notification/snackbarNotificationSlice';

const handleClose = (dispatch: AppDispatch, event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }

    dispatch(setOpen(false));
};

const NotifySnackbar = () => {
    const { open, messages, color, messageString } = useSelector((state: RootState) => state.snackbarNotification);
    const dispatch = useDispatch();

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            message={messageString}
            key={'mdsirtopright'}
            autoHideDuration={6000}
            onClose={(event, reason) => handleClose(dispatch, event, reason)}
            action={
                <React.Fragment>
                    {/* <Button color="secondary" size="small" onClick={handleClose}>
                        UNDO
                    </Button> */}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={() => dispatch(setOpen(false))}
                    >
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
            }
        />
    );
}
export default NotifySnackbar;