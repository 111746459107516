import * as React from 'react';
import {
    Typography,
    Toolbar,
    IconButton,
} from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

const SideWindowHeader = ({ title, onClickBack }: { title: string, onClickBack: Function }) => {
    return (
        <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => onClickBack()}
            >
                <ArrowBackIosOutlinedIcon />
            </IconButton>
            <Typography variant="h6" component="div" align='center' sx={{ flexGrow: 1 }}>
                {title}
            </Typography>
        </Toolbar>
    );
}
export default SideWindowHeader;